body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-pagination .swiper-pagination-bullet {
  @apply w-2 h-2 bg-Gray90 rounded-full mx-1;
}

.custom-pagination .swiper-pagination-bullet-active {
  @apply w-4 h-2 bg-black rounded-full;
}

@keyframes expand {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 405px;
    opacity: 1;
  }
}

.animate-expand {
  animation: expand 0.5s ease-in-out forwards;
}

.text-premium {
  font-family: 'Pretendard', sans-serif;
  font-weight: 600; /* SemiBold */
}